import { Injectable } from '@angular/core';
import { ApiService, HttpStatus } from '@mm-ui/core';
import { url } from '@hlp-core/services/user/user.url';
import { catchError, tap } from 'rxjs/operators';
import { AsyncSubject, Observable, of, Subject, throwError } from 'rxjs';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { Profile } from '@hlp-core/services/user/profile';
import { Path } from '@hlp-shared/constants/path.constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static $initialized: Subject<boolean> = new AsyncSubject();

  static initialized() {
    UserService.$initialized.next(true);
    UserService.$initialized.complete();
  }

  profile: Profile;

  constructor(
    private api: ApiService,
    private router: Router
  ) {}

  getProfile(options: {[key: string]: any}): Observable<Profile> {
    return this.api.get(url.profile, null, options, { isCacheEnabled: true })
      .pipe(
        catchError((error: HttpErrorExtResponse) => {
          if (error.status === HttpStatus.CODE401) {
            return of({});
          } else {
            this.router.navigate(['/', Path.unavailable]);

            return throwError(error);
          }
        }),
        tap((response: Profile) => this.profile = response)
      );
  }
}
