import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatus } from '@mm-ui/core';
import { environment } from '@hlp-env/environment';
import { Router } from '@angular/router';
import { Path } from '@hlp-app/shared/constants/path.constant';

@Injectable({ providedIn: 'root' })
export class CustomInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError(resp => {
        if (resp instanceof HttpErrorResponse) {
          switch (resp.status) {
            case HttpStatus.CODE401:
              if (environment.production) {
                window.location.href = environment.smsAppPath;
              } else {
                this.router.navigate([Path.signin]);
              }
              break;
            case HttpStatus.CODE403:
              this.router.navigate([Path.forbidden]);
          }
        }

        return throwError(resp);
      }));
  }
}
