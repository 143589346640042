import { Injectable } from '@angular/core';
import { RefreshStore } from './refresh.store';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  constructor(private refreshStore: RefreshStore) {}

  refreshPage() {
    this.refreshStore.update({
      page: {
        refresh: true
      }
    });
  }

  finishPageRefresh() {
    this.refreshStore.update({
      page: {
        refresh: false
      }
    });
  }
}
