<ng-container *ngIf="!isCompact">
  <hlp-breadcrumbs></hlp-breadcrumbs>
  <div class="hlp-filters m-row">
    <div class="m-col-xs12of12">
      <mm-bar>
        <mm-bar-item>
          <h2>
            {{ 'PAGE.SEARCH_HEADER' | translate: { category: getCategoryName() | translate } }}
          </h2>
        </mm-bar-item>
      </mm-bar>
      <ng-container *ngTemplateOutlet="search"></ng-container>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</ng-container>

<div
  *ngIf="isCompact && (isCompactVisible$ | async)"
  [popover]="compactContent"
  containerClass="m-popover hlp-filters-popover"
  placement="bottom left"
  container="body"
  [outsideClick]="true"
  class="hlp-filters-container-compact"
  (click)="onSearchClick()"
  [isOpen]="isPanelOpen"
  triggers=""
  [adaptivePosition]="false"
  (onHidden)="isPanelOpen = false"
  (keyup.enter)="redirectToFiltersPanel()"
>
  <ng-container *ngTemplateOutlet="search"></ng-container>
</div>

<ng-template #search>
  <mm-filters
    (filterChange)="onFilterChange($event)"
    [filtersReset$]="clear$.asObservable()"
    [searchDelay]="delay"
    [searchPlaceholder]="'PAGE.SEARCH_PLACEHOLDER' | translate"
    [searchValue]="searchValue"
  ></mm-filters>
</ng-template>

<ng-template #content>
  <div *ngIf="pages$ | async as pages" class="hlp-filters">
    <div *ngFor="let page of pages" class="hlp-filters__page">
      <mm-bar>
        <mm-bar-item>
          <h2>
            <a target="_blank" [routerLink]="['/', path.article, pageService.category, page.file]">{{ page.title }}</a>
          </h2>
        </mm-bar-item>
        <mm-bar-item class="hlp-filters__date">
          <span>{{ page.date | mmDate: this.settings.locale.shortDate }}</span>
        </mm-bar-item>
        <mm-bar-item pushRight> </mm-bar-item>
      </mm-bar>
      <div class="hlp-filters__keyword">
        <span mmTag *ngFor="let keyword of page.keywords">
          {{ keyword }}
        </span>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="noData"></ng-container>
  </div>
</ng-template>

<ng-template #compactContent>
  <div *ngIf="pages$ | async as pages" class="hlp-filters">
    <h4>
      <a (click)="redirectToFiltersPanel()">{{ 'PAGE.NAVIGATE_TO_FILTERS' | translate }}</a>
    </h4>
    <div *ngFor="let page of pages">
      <mm-bar>
        <mm-bar-item>
          <h3>
            <a target="_blank" [routerLink]="['/', path.article, pageService.category, page.file]">{{ page.title }}</a>
          </h3>
        </mm-bar-item>
      </mm-bar>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="noData"></ng-container>
</ng-template>

<ng-template #noData>
  <div *ngIf="!(pages$ | async).length && searchValue" class="hlp-no-data-box">
    <mm-no-data-content [message]="'PAGE.NO_SEARCH_RESULTS' | translate"></mm-no-data-content>
  </div>
</ng-template>
