import { Injectable } from '@angular/core';
import { ApiService, I18nLocaleData } from '@mm-ui/core';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { url } from './settings.url';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  static $initialized: Subject<boolean> = new AsyncSubject();

  static initialized() {
    SettingsService.$initialized.next(true);
    SettingsService.$initialized.complete();
  }

  config: ApplicationConfig = {
    invoiceDueDateWarningDays: 3
  };

  locale: I18nLocaleData = {
    id: 'en',
    shortMonthYear: 'MMM YYYY',
    shortDateMonth: 'DD MMM',
    shortDateDotMonth: 'DD. MMM',
    shortDate: 'DD MMM YYYY',
    shortDateTime: 'DD MMM YYYY HH:mm:ss',
    shortDateFullTime: 'DD MMM YYYY HH:mm:ss.SSS',
    currencySymbol: '€',
    currency: 'EUR'
  };

  featureFlags: {[key: string]: boolean} = {};

  constructor(private api: ApiService) {}

  getCurrencySymbol() {
    return this.locale.currencySymbol || this.locale.currency;
  }

  getFeatureFlags(options: {[key: string]: any} = null): Observable<{[key: string]: boolean}> {
    return this.api.get(url.featureFlags, null, options, {isCacheEnabled: true})
      .pipe(
        tap((response: {[key: string]: boolean}) => this.featureFlags = response)
      );
  }
}

export interface ApplicationConfig {
  invoiceDueDateWarningDays: number;
}

export interface LocaleConfig {
  id: string;
  shortDate: string;
  date: string;
}
