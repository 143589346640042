import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Contents } from '@hlp-core/services/categories/categories.service';
import { PageService } from '@hlp-core/services/page/page.service';
import { Path } from '@hlp-shared/constants/path.constant';

@Component({
  selector: 'hlp-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent {
  @Input() set contents(value: Contents[]) {
    this.parsedContents = [];
    this.topLevel = value.map(item => {
      const contents = (item.contents || []).map((content: Contents) => {
        const routerLink = content.path ? ['/', Path.article, ...content.path]  : ['/', Path.article, this.page.category, content.file];
        const rights = content.rights || { $any: '' };

        return {...content, routerLink, rights} as Contents;
      });

      this.parsedContents.push(...contents);

      return item.title;
    });

  }
  topLevel: string[] = [];
  parsedContents: Contents[] = [];

  constructor(private page: PageService) {}
}
