import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MmActionTableMenuModule,
  MmBarModule,
  MmButtonModule,
  MmContextualMenuModule,
  MmFormFieldModule,
  MmIconModule,
  MmModalModule,
  MmNavigationHeaderBreadcrumbModule,
  MmPageModule,
  MmPanelModule,
  MmDatepickerModule,
  MmSidebarModule,
  MmTabgroupModule,
  MmTabModule,
  MmTagModule,
  MmVerticalHeaderModule,
  MmTextareaModule,
  MmAlertModule,
  MmLoaderModule,
  MmTypeaheadModule,
  MmInfoBoxModule,
  MmSpinnerModule,
  MmCheckboxModule,
  MmStarModule,
  MmPipesModule,
  MmSelectModule,
  MmSegmentedControlModule,
  MmFiltersModule, MmSeparatorModule, MmNoDataContentModule
} from '@mm-ui/components';
import { BreadcrumbsComponent } from '@hlp-shared/components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { Table3dotsMenuComponent } from '@hlp-shared/components/table-3dots-menu/table-3dots-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '@mm-ui/core';
import { AddSignPipe } from '@hlp-shared/helpers/pipes/add-sign.pipe';
import { NumberValueClassDirective } from '@hlp-shared/helpers/directives/numberValueClass.directive';
import { ActionMenuComponent } from '@hlp-shared/components/action-menu/action-menu.component';
import { ModalConfirmWindowComponent } from '@hlp-shared/components/modal-confirm-window/modal-confirm-window.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MainMenuComponent } from '@hlp-shared/components/main-menu/main-menu.component';
import { SideMenuComponent } from '@hlp-shared/components/side-menu/side-menu.component';
import { FiltersComponent } from '@hlp-app/modules/article/filters/filters.component';
import { PopoverModule } from 'ngx-bootstrap/popover';

const reexport = [
  CommonModule,
  RouterModule,
  MmBarModule,
  MmButtonModule,
  MmNavigationHeaderBreadcrumbModule,
  MmPanelModule,
  MmTabModule,
  MmTabgroupModule,
  MmPageModule,
  MmTagModule,
  MmIconModule,
  MmContextualMenuModule,
  MmActionTableMenuModule,
  MmModalModule,
  MmFormFieldModule,
  MmDatepickerModule,
  MmSidebarModule,
  MmVerticalHeaderModule,
  MmTextareaModule,
  MmAlertModule,
  MmLoaderModule,
  MmTypeaheadModule,
  MmInfoBoxModule,
  MmSpinnerModule,
  MmCheckboxModule,
  MmStarModule,
  MmPipesModule,
  PermissionsModule,
  MmSelectModule,
  MmSegmentedControlModule,
  MmFiltersModule,
  PermissionsModule,
  MmSeparatorModule,
  MmNoDataContentModule
];

const ownExport = [
  BreadcrumbsComponent,
  Table3dotsMenuComponent,
  AddSignPipe,
  NumberValueClassDirective,
  ActionMenuComponent,
  ModalConfirmWindowComponent,
  MainMenuComponent,
  SideMenuComponent,
  FiltersComponent
];

@NgModule({
  imports: [
    ...reexport,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MmAlertModule,
    MmLoaderModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot()
  ],
  declarations: ownExport,
  providers: [],
  exports: [...reexport, ...ownExport, FormsModule, ReactiveFormsModule, TranslateModule, TooltipModule],
  entryComponents: []
})
export class SharedModule {}
