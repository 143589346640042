import { Contents } from '@hlp-app/modules/core/services/categories/categories.service';
import { DocumentSearchOptions, IndexOptionsForDocumentSearch, Stemmer } from 'flexsearch';

const stemmer: Stemmer = {
  ational: 'ate',
  iveness: 'ive',
  fulness: 'ful',
  ousness: 'ous',
  ization: 'ize',
  tional: 'tion',
  biliti: 'ble',
  icate: 'ic',
  ative: '',
  alize: 'al',
  iciti: 'ic',
  entli: 'ent',
  ousli: 'ous',
  alism: 'al',
  ation: 'ate',
  aliti: 'al',
  iviti: 'ive',
  ement: '',
  enci: 'ence',
  anci: 'ance',
  izer: 'ize',
  alli: 'al',
  ator: 'ate',
  logi: 'log',
  ical: 'ic',
  ance: '',
  ence: '',
  ness: '',
  able: '',
  ible: '',
  ment: '',
  eli: 'e',
  bli: 'ble',
  ful: '',
  ant: '',
  ent: '',
  ism: '',
  ate: '',
  iti: '',
  ous: '',
  ive: '',
  ize: '',
  al: '',
  ou: '',
  er: '',
  ic: '',
  ing: '',
  s: ''
};

export const IndexSearchConfig = {
  charset: 'latin:balance',
  tokenize: 'forward',
  ...stemmer,
  document: {
    id: 'filePath',
    index: ['text', 'filePath']
  }
} as IndexOptionsForDocumentSearch<Partial<Contents>>;

export const  QuerySearchConfig = {
  index: ['text', 'filePath']
} as DocumentSearchOptions<false>;
