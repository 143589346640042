import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CategoriesService } from '@hlp-core/services/categories/categories.service';
import { UserService } from '@hlp-core/services/user/user.service';
import { environment } from '@hlp-env/environment';
import { MainMenu } from '@hlp-shared/components/main-menu/main-menu';
import { Path } from '@hlp-shared/constants/path.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hlp-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  @ViewChild('profileMenu', { static: true }) profileMenu: TemplateRef<any>;

  @Input() canExpand = false;

  path = Path;
  smsAppPath = environment.smsAppPath;
  mainMenu: MainMenu[];
  bottomMenu: MainMenu[];
  profileSettingsHref = '';
  logoutHref = `${this.smsAppPath}logout`;

  constructor(
    private categoriesService: CategoriesService,
    private translate: TranslateService,
    user: UserService
  ) {
    this.profileSettingsHref = `${this.smsAppPath}profiles/${user.profile.id}`;
  }

  ngOnInit() {
    const langKeys = this.categoriesService.categories.map(item => item.name);

    this.translate.get(
      [
        ...langKeys,
        'NAVIGATION.BOTTOM.PROFILE',
        'NAVIGATION.BOTTOM.LOGOUT'
      ]
    ).subscribe(lang => {
        this.mainMenu = this.categoriesService.categories.map(({ icon, name, prefix, rights }) => ({
          icon,
          title: lang[name],
          routerObj: { link: `/${Path.article}/${prefix}/home` },
          rights
        }));

        this.bottomMenu = [
          {
            icon: 'profile-filled',
            title: lang['NAVIGATION.BOTTOM.PROFILE'],
            menuTemplate: this.profileMenu,
            menuClass: 'fnc-main-menu__profile-menu',
            rights: { $any: [''] }
          }
        ];
      }
    );
  }
}
