<mm-sidebar class="hlp-side-menu m-sidebarNavigation">
  <ng-container *ngFor="let item of topLevel; let i = index">
    <div *ngIf="item" mmSidebarTitle class="hlp-side-menu__title">{{ item }}</div>
    <hlp-filters [isCompact]="true"></hlp-filters>
    <hr *ngIf="!item" class="hlp-side-menu__hr" />
    <ng-container *ngFor="let page of parsedContents">
      <ng-container *mmPermissions="page.rights">
        <a mmSidebarLink [routerLink]="page.routerLink" routerLinkActive="m-sidebarNavigation-isActive-reloadable">
          {{ page.title }}
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</mm-sidebar>
