import { Injectable } from '@angular/core';
import { from, Observable, of, zip } from 'rxjs';
import { ApiService, LocalizationService } from '@mm-ui/core';
import { url } from './page.url';
import { DbService } from '@hlp-core/services/db/db.service';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  category: string;
  appStartTimeStamp = Date.now();

  constructor(
    private api: ApiService,
    private localization: LocalizationService,
    private dbService: DbService
  ) {}

  loadPage(
    pageName: string, date: string, params: {[key: string]: unknown} = {}
    ): Observable<string> {
    const language = this.localization.currentLanguage;
    const filePath = `${this.category}/${language}/${pageName}`;
    const timeStamp = { _t: this.appStartTimeStamp };

    return from(this.dbService.db).pipe(
      mergeMap(db => zip(of(db), from(db.get('pages', filePath)))),
      mergeMap(([db, page]) => {
        if (!page || !date || page.date !== date) {
          return this.api.get(
            url.page,
            { category: this.category, language, page: pageName },
            { ...params, ...timeStamp},
            { isCacheEnabled: false, ignoreBasePath: true, disableCamelCaseConverting: true, responseType: 'text' }
          ).pipe(
            tap(text => db.put('pages', { date, text, filePath }))
          );
        }

        return of(page.text);
      })
    );
  }
}
