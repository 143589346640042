import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { SettingsService } from '@hlp-core/services/settings/settings.service';
import { LocalizationService } from '@mm-ui/core';
import { UserService } from '@hlp-core/services/user/user.service';
import { CategoriesService } from '@hlp-core/services/categories/categories.service';

@Injectable()
export class InitGuard implements CanActivate {
  canActivate() {
    return zip(
      LocalizationService.initialized.pipe(first()),
      SettingsService.$initialized,
      UserService.$initialized,
      CategoriesService.$initialized
    ).pipe(
      map((initiliazed: boolean[]) => initiliazed.every(item => item))
    );
  }
}
