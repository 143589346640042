import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@hlp-core/core.module';
import { DbService } from '@hlp-core/services/db/db.service';
import { environment } from '@hlp-env/environment';
import { SharedModule } from '@hlp-shared/shared.module';
import { MmDatepickerService } from '@mm-ui/components';
import { ApiModule, LocalizationModule, LocalizationService, PermissionsGuard } from '@mm-ui/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function initializeApp(db: DbService) {
  return (): Promise<any> => db.connectToDb();
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.blockquote = (text: string) =>
    `<div class="m-info-box bg-metro-yellow-tint-90">
       <div class="m-info-box-title">Note</div>
       <div class="m-info-box-description">${text}</div>
    </div>`;

  renderer.link = (href: string, title: string, text: string) => {
    if (href.includes('https://') || href.includes('http://')) {
      return `<a target="_blank" class="markdown__link-external" href="${href}" title="${title || ''}">
                ${text}<img class="markdown__link-icon" src="/img/metro/icons/src/icon_link-out.svg">
              </a>`;
    }

    return `<a target="_blank" href="${href}" title="${title || ''}">${text}</a>`;
  };

  renderer.image = (href: string, title: string, text: string) => {
    if (href.includes('@1x')) {
      const img2x = href.replace('@1x', '@2x');

      return `<img src="${href}" alt="${text}" title="${text}" srcset="${img2x} 2x" loading="lazy">`;
    } else {
      return `<img src="${href}" alt="${text}" title="${text}" loading="lazy">`;
    }
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: true
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    ApiModule.forRoot({ basePath: environment.baseApiPath }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory
      }
    }),
    LocalizationModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  providers: [
    LocalizationService,
    PermissionsGuard,
    MmDatepickerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DbService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
