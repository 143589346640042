import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AnchorPropertiesInterface } from '@mm-ui/components/lib/components/breadcrumb/mm-navigation-header-breadcrumb.component';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'hlp-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: AnchorPropertiesInterface[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.getBreadcrumbs();
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.getBreadcrumbs());
  }

  ngOnDestroy() {
  }

  private getBreadcrumbs() {
    const root: ActivatedRoute = this.activatedRoute.root;
    this.breadcrumbs = [{ label: 'NAVIGATION.MAIN.HOME', link: '/' }, ...this.prepareBreadcrumbs(root)];
    this.breadcrumbs.pop();
  }

  private prepareBreadcrumbs(
    route: ActivatedRoute, url: string = '', breadcrumbs: AnchorPropertiesInterface[] = []
  ): AnchorPropertiesInterface[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.prepareBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      url += `/${routeURL}`;
      const breadcrumb: AnchorPropertiesInterface = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        link: url
      };
      child.snapshot.routeConfig.path !== '' && breadcrumbs.push(breadcrumb);

      return this.prepareBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
