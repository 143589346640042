import { Component, NgZone } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { FeatureFlagsService, getUrlParameter, LocalizationResponse, LocalizationService, PermissionsService } from '@mm-ui/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { MmLoaderService, MmDatepickerService } from '@mm-ui/components';
import { SettingsService } from '@hlp-core/services/settings/settings.service';
import { UserService } from '@hlp-core/services/user/user.service';
import { filter, mergeMap } from 'rxjs/operators';
import { Profile } from '@hlp-core/services/user/profile';
import { environment } from '@hlp-env/environment.local';
import { akitaDevtools } from '@datorama/akita';
import { RefreshService } from '@hlp-core/services/refresh/state/refresh.service';
import { zip } from 'rxjs';
import { CategoriesService, Category } from '@hlp-core/services/categories/categories.service';
import { Path } from '@hlp-shared/constants/path.constant';

@Component({
  selector: 'hlp-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  appStartTimestamp = Date.now();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private loader: MmLoaderService,
    private localization: LocalizationService,
    private user: UserService,
    private permissions: PermissionsService,
    private settings: SettingsService,
    private featureFlags: FeatureFlagsService,
    private dp: MmDatepickerService,
    private refresh: RefreshService,
    private categories: CategoriesService
  ) {
    if (!environment.production) {
      akitaDevtools(this.ngZone);
    }

    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');

    router.events.pipe(
      filter((event) : event is RouterEvent => event instanceof RouterEvent)
    ).subscribe(event => {
      this.routerInterceptor(event);
    });
  }

  private routerInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      window.scrollTo(0, 0);
      const timeStamp = {t_: this.appStartTimestamp};
      this.refresh.refreshPage();
      if (event.url.includes(Path.unavailable)) {
        return;
      }
      this.loader.stopForce();
      this.loader.startGlobal();
      this.user.getProfile(timeStamp)
        .pipe(
          mergeMap((response: Profile) => {
            // todo: temporary disable choosing locale from params or user's settings
            // const locale = getUrlParameter('locale') || response.locale || 'en';
            const locale = 'en';
            this.dp.setDatePickerLocale(locale);

            return zip(
              this.localization.loadLanguage(locale, timeStamp),
              this.settings.getFeatureFlags(timeStamp),
              this.categories.loadCategories(timeStamp)
            );
          })
        )
        .subscribe(([i18n, flags, categories]: ([LocalizationResponse, {[key: string]: boolean}, Category[]])) => {
          this.settings.locale = i18n.localeData;
          this.user.profile.roles != null && UserService.initialized();
          this.permissions.rightsList = this.user.profile.roles || [];
          this.featureFlags.setFlags(this.settings.featureFlags);
          SettingsService.initialized();
          LocalizationService.initialized.next(true);
        });
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.refresh.finishPageRefresh();
      this.loader.stop();
    }
  }
}
