import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { ApiService, LocalizationService, PermissionsService, Right } from '@mm-ui/core';
import { tap } from 'rxjs/operators';
import { url } from '@hlp-core/services/categories/categories.url';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  static $initialized: Subject<boolean> = new AsyncSubject<boolean>();
  static $initializedContents: Subject<boolean> = new AsyncSubject<boolean>();

  categories: Category[] = [];
  contents: Contents[] = [];

  private serviceTimeStamp = {_t: Date.now()};

  constructor(
    private api: ApiService,
    private localization: LocalizationService,
    private permissions: PermissionsService
  ) {}

  loadCategories(params: {[key: string]: any} = {}): Observable<Category[]> {
    return this.api.get(url.categories, undefined, params, {
      isCacheEnabled: true,
      ignoreBasePath: true,
      disableCamelCaseConverting: true
    }).pipe(
      tap(response => {
        this.categories = response;
        CategoriesService.$initialized.next(true);
        CategoriesService.$initialized.complete();
      })
    );
  }

  loadContents(category: string): Observable<Contents[]> {
    const language = this.localization.currentLanguage;

    return this.api.get(url.contents, { category, language }, this.serviceTimeStamp, {
        isCacheEnabled: true,
        ignoreBasePath: true,
        disableCamelCaseConverting: true
      })
      .pipe(
        tap((response: Contents[]) => {
          this.contents = response;
          CategoriesService.$initializedContents.next(true);
          CategoriesService.$initializedContents.complete();
        })
      );
  }

  findByKeywords(keywords: string[]) {
    let pages: Contents[] = [];

    this.contents.forEach(topLevel => {
      const keywordFilteredContent = topLevel.contents?.filter(page => page.keywords.some(keyword => keywords.includes(keyword)));
      const permittedContent = keywordFilteredContent?.
        filter(page => !page.rights || this.permissions.isPermitted(page.rights));

      pages = [
        ...pages,
        ...permittedContent
      ];
    });

    return pages;
  }
}

export interface Category {
  name: string;
  prefix: string;
  icon: string;
  rights: (Right | string)[] | (Right | string);
}

export interface Contents {
  title: string;
  description?: string;
  file: string;
  date?: string;
  keywords: string[];
  rights?: (Right | string)[] | (Right | string);
  contents?: Contents[];
  katex?: boolean;
  path?: string[];
}
