import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Path } from './shared/constants/path.constant';
import { InitGuard } from '@hlp-core/guards/init.guard';
import { SigninPageComponent } from '@hlp-core/components/signin-page/signin-page.component';
import { environment } from 'src/environments/environment';
import { NoPermissionsPageComponent } from '@hlp-core/components/no-permissions-page/no-permissions-page.component';
import { UnavailablePageComponent } from '@hlp-core/components/unavailable-page/unavailable-page.component';

const routes: Routes = [
  {
    path: Path.home,
    canActivate: [InitGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data: {
      breadcrumb: 'NAVIGATION.MAIN.HOME'
    }
  },
  {
    path: Path.article,
    canActivate: [InitGuard],
    loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule),
    data: {
      breadcrumb: 'NAVIGATION.MAIN.HELP_CENTER'
    }
  },
  {
    path: Path.forbidden,
    canActivate: [InitGuard],
    component: NoPermissionsPageComponent
  },
  {
    path: Path.unavailable,
    component: UnavailablePageComponent
  },
  environment.production ? { path: Path.signin, redirectTo: Path.home } : { path: Path.signin, component: SigninPageComponent },
  { path: '**', redirectTo: Path.home }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
