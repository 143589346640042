import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from '@hlp-shared/shared.module';
import { InitGuard } from '@hlp-core/guards/init.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from '@hlp-core/services/custom-interceptor/custom-interceptor.service';
import { SigninPageComponent } from '@hlp-core/components/signin-page/signin-page.component';
import { NoPermissionsPageComponent } from '@hlp-core/components/no-permissions-page/no-permissions-page.component';
import { UnavailablePageComponent } from '@hlp-core/components/unavailable-page/unavailable-page.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule
  ],
  declarations: [
    NoPermissionsPageComponent,
    UnavailablePageComponent,
    SigninPageComponent
  ],
  providers: [
    InitGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
