<mm-vertical-header [canExpand]="canExpand">
  <ng-container *ngFor="let item of mainMenu">
    <mm-vh-nav-item
      *mmPermissions="item.rights; operator: 'OR'"
      [icon]="item.icon"
      [title]="item.title"
      [href]="item.href"
      [routeObj]="item.routerObj"
    ></mm-vh-nav-item>
  </ng-container>

  <ng-container bottom *ngFor="let item of bottomMenu">
    <mm-vh-nav-item
      *mmPermissions="item.rights; operator: 'OR'"
      [icon]="item.icon"
      [title]="item.title"
      [href]="item.href"
      [menuClass]="item.menuClass"
      [menuTemplate]="item.menuTemplate"
      [routeObj]="item.routeObj"
      class="m-verticalHeader-button-isActive"
      bottom
    ></mm-vh-nav-item>
  </ng-container>
</mm-vertical-header>

<ng-template #profileMenu>
  <mm-sidebar>
    <div mmSidebarTitle>{{ 'NAVIGATION.BOTTOM.PROFILE' | translate }}</div>
    <a [href]="profileSettingsHref" icon="settings" mmSidebarLink>{{ 'NAVIGATION.BOTTOM.SETTINGS' | translate }}</a>
    <a [href]="logoutHref" icon="logout" mmSidebarLink>{{ 'NAVIGATION.BOTTOM.LOGOUT' | translate }}</a>
  </mm-sidebar>
</ng-template>
