import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@mm-ui/core';
import { Router } from '@angular/router';
import { Path } from '@hlp-app/shared/constants/path.constant';
import { MmLoaderService } from '@mm-ui/components';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './signin-page.component.html'
})
export class SigninPageComponent {
  form: FormGroup;

  constructor(
    private loader: MmLoaderService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.router.onSameUrlNavigation = 'ignore';
  }

  onSubmit() {
    const username = this.form.value.username;
    const password = this.form.value.password;
    const request = { username, password };
    this.loader.startGlobal();
    this.api.post('login', {}, request)
      .pipe(finalize(() => this.loader.stopForce()))
      .subscribe((response: any) => {
          document.cookie = `apiKey=${response.apiKey}; path=/`;
          this.router.navigate([Path.home])
            .then(() => {
              this.router.onSameUrlNavigation = 'reload';
            });
        }
      );
  }
}
