import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CategoriesService, Contents } from '@hlp-app/modules/core/services/categories/categories.service';
import { FilterPanelService } from '@hlp-app/modules/core/services/filter-panel/filter-panel.service';
import { PageService } from '@hlp-app/modules/core/services/page/page.service';
import { SettingsService } from '@hlp-app/modules/core/services/settings/settings.service';
import { DEBOUNCE_SEARCH_DELAY } from '@hlp-app/shared/constants/delay.constant';
import { Path } from '@hlp-app/shared/constants/path.constant';
import { QueryMapConfig } from '@hlp-app/shared/constants/query-map-config.constant';
import { FiltersService } from '@mm-ui/components';
import { FilterChange } from '@mm-ui/components/lib/components/filters/types/filters.interface';
import { QueryParamsService } from '@mm-ui/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'hlp-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit {
  @Input() isCompact = false;
  path = Path;
  searchValue: string;
  isPanelOpen = false;
  clear$ = new Subject();
  isCompactVisible$ = new BehaviorSubject(true);
  pages$: BehaviorSubject<Contents[]> = new BehaviorSubject([]);

  readonly delay = DEBOUNCE_SEARCH_DELAY;
  private isContentReceived = false;
  private filtersState: FilterChange[] = [];
  private isFilterSelectedManually = false;

  constructor(
    public settings: SettingsService,
    public pageService: PageService,
    private readonly router: Router,
    private readonly queryParamsService: QueryParamsService,
    private readonly filtersService: FiltersService,
    private readonly filterPanelService: FilterPanelService
  ) { }

  ngOnInit() {
    if (this.isCompact) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.setCompactVisibility();
        });

      this.setCompactVisibility();

      return;
    }

    CategoriesService.$initializedContents
      .subscribe(() => {
        this.isContentReceived = true;
        this.setFiltersFromQueryParams();
      });

    this.initQueryParams();
  }

  onFilterChange(filterChanges: FilterChange[]) {
    this.isFilterSelectedManually = true;
    this.searchValue = this.filtersService.getSearchValue(filterChanges);
    this.filtersState = filterChanges;

    if (this.isCompact) {
      this.isPanelOpen = !!this.searchValue;
    } else {
      this.setFiltersQueryParams(filterChanges);
    }

    this.search(this.searchValue);
  }

  onSearchClick() {
    this.isPanelOpen = this.searchValue ? !this.isPanelOpen : false;
  }

  redirectToFiltersPanel() {
    if (this.searchValue && this.isCompact) {
      const panelUrl = this.router.createUrlTree(
        ['/', Path.article, this.pageService.category, Path.filtersPanel],
        {
          queryParams: this.queryParamsService.getEncodedQueryParams({
            filters: this.filtersService.mapFiltersToQueryParams(this.filtersState)
          })
        })
        .toString();
      window.open(panelUrl);
    }
  }

  getCategoryName() {
    return `CATEGORY.${this.pageService.category.replace('-', '_').toUpperCase()}`;
  }

  private search(query: string) {
    this.filterPanelService.searchArticlesInCategory(query).subscribe(pages =>  this.pages$.next(pages));
  }

  private setCompactVisibility() {
    this.isCompactVisible$.next(!this.router.url.includes('filters-panel'));
  }

  private initQueryParams() {
    this.queryParamsService.paramsChange$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (!this.isFilterSelectedManually && !this.isCompact && this.isContentReceived) {
          this.setFiltersFromQueryParams();

          return;
        }

        this.isFilterSelectedManually = false;
      });
  }

  private setFiltersFromQueryParams() {
    const filterParams = this.getParams().filters;

    if (!filterParams) {
      this.searchValue = '';

      return;
    }

    const filtersState = this.filtersService.mapQueryParamsToFilters(filterParams);
    const filtersConfig = this.filtersService.setSelectedValues(filtersState, []);
    this.searchValue = filtersConfig.search;
    this.filtersState = filtersState;
    this.search(this.searchValue);
  }

  private setFiltersQueryParams(filtersChanges: FilterChange[]) {
    const currentParams = this.getParams();
    const filtersParams = this.filtersService.mapFiltersToQueryParams(filtersChanges);
    this.queryParamsService.setParams({
      ...currentParams,
      filters: filtersParams
    });
  }

  private getParams() {
    return this.queryParamsService.getParams(QueryMapConfig);
  }
}
