import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Refresh } from './refresh';
import { Injectable } from '@angular/core';

export interface RefreshState extends EntityState<Refresh> {}

const initialState = {
  page: { refresh: false }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'refresh' })
export class RefreshStore extends EntityStore<RefreshState, Refresh> {
  constructor() {
    super(initialState);
  }
}
