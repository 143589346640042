import { Injectable } from '@angular/core';
import { CategoriesService, Contents } from '@hlp-app/modules/core/services/categories/categories.service';
import { DbService } from '@hlp-app/modules/core/services/db/db.service';
import { SettingsService } from '@hlp-app/modules/core/services/settings/settings.service';
import { IndexSearchConfig, QuerySearchConfig } from '@hlp-app/shared/constants/search-config.constant';
import { ApiService, LocalizationService } from '@mm-ui/core';
import { Document } from 'flexsearch';
import { IDBPDatabase } from 'idb';
import { combineLatest, from, Observable, of, zip } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { url } from '../../services/page/page.url';
import { HelpCenterDB } from '../db/help-center-db';
import { PageModel } from '../db/page-model';
import { PageService } from '../page/page.service';

@Injectable({
  providedIn: 'root'
})

export class FilterPanelService {
  private appStartTimeStamp = Date.now();
  private searchIndex: Document<Partial<Contents>>;
  private category: string;
  private availablePages: Contents[] = [];

  constructor(
    private categories: CategoriesService,
    public settings: SettingsService,
    private api: ApiService,
    private localization: LocalizationService,
    private dbService: DbService,
    private page: PageService
  ) {
  }

  searchArticlesInCategory(query: string) {
    const category = this.page.category;

    if (this.category !== category) {
      this.searchIndex = null;
      this.category = category;
      this.availablePages = [];
      this.categories.contents.forEach(topLevel => {
        this.availablePages = [
          ...this.availablePages,
          ...topLevel.contents
        ];
      });
    }

    return this.searchIndex
      ? of(this.searchArticles(query))
      : from(this.dbService.db)
        .pipe(
          mergeMap(db => zip(of(db), from(db.getAll('pages')))),
          mergeMap(([db, pages]) => combineLatest(this.getPagesToLoad(pages, db))),
          tap(pages => this.addArticlesToSearch(pages)),
          map(() => this.searchArticles(query))
        );
  }

  private getPagesToLoad(dbPages: PageModel[], db: IDBPDatabase<HelpCenterDB>) {
    const pagesToLoad: Observable<PageModel>[] = [];

    this.availablePages.forEach(availablePage => {
      const filePath = this.getPagePath(availablePage.file);
      const matchPage = dbPages.find(page => page.filePath === filePath);
      const timeStamp = { _t: this.appStartTimeStamp };

      matchPage
        ? pagesToLoad.push(of(matchPage))
        : pagesToLoad.push(this.api.get(
          url.page,
          { category: this.page.category, language: this.localization.currentLanguage, page: availablePage.file },
          { ...timeStamp },
          { isCacheEnabled: false, ignoreBasePath: true, disableCamelCaseConverting: true, responseType: 'text' }
        ).pipe(
          map(text => ({ date: availablePage.date, text, filePath })),
          tap(uploadData =>  db.put('pages', uploadData))
        ));
    });

    return pagesToLoad;
  }

  private getPagePath(fileName: string) {
    return `${this.page.category}/${this.localization.currentLanguage}/${fileName}`;
  }

  private addArticlesToSearch(pages: PageModel[]) {
    if (!this.searchIndex) {
      this.searchIndex = new Document(IndexSearchConfig);
    }
    pages.forEach(page => this.searchIndex.add(page.filePath, page));
  }

  private searchArticles(query: string) {
    const foundPagesIndexes = this.searchIndex.search(query, QuerySearchConfig);

    return this.availablePages.filter(page => {
      const pagePathIndex = foundPagesIndexes.find(index => index.result.includes(this.getPagePath(page.file)));

      return foundPagesIndexes.includes(pagePathIndex);
    });
  }
}
