import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';

export const url: {[key: string]: URLDescriptor} = {
  categories: {
    url: 'article/categories.json'
  },
  contents: {
    url: 'article/:category/:language/contents.json'
  }
};
