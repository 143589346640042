import { Injectable } from '@angular/core';
import { IDBPDatabase, openDB } from 'idb';
import { environment } from '@hlp-env/environment';
import { HelpCenterDB } from '@hlp-core/services/db/help-center-db';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  db: Promise<IDBPDatabase<HelpCenterDB>>;

  connectToDb(): Promise<IDBPDatabase<HelpCenterDB>> {
    this.db = openDB<HelpCenterDB>('helpcenter', environment.dbVersion, {
      upgrade(database) {
        if (database.objectStoreNames.contains('pages')) {
          database.deleteObjectStore('pages');
        }
        database.createObjectStore('pages', { keyPath: 'filePath' });
      }
    });

    return this.db;
  }
}
